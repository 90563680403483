import React from "react";

function Footer() {
    return (
        <footer>

        </footer>
    );
    }

export default Footer;